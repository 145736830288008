import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = 'https://api.baka.net/v1';
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        });
    }
    options.credentials = 'include';
    return fetchUtils.fetchJson(url, options);
};

export default {
    getList: (resource, params) => {
        const url = `${apiUrl}/domains`;

        return httpClient(url).then(({ json }) => ({
            data: json,
            total: json.length,
        }));
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/domains/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    create: (resource, params) =>
        httpClient(`${apiUrl}/domains`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    update: (resource, params) =>
        httpClient(`${apiUrl}/domains/${params.id}/recordTable`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/domains/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    updateUserInfo: (params) =>
        httpClient(`${apiUrl}/users/me`, {
            method: 'PUT',
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({data: json})),

    initUserApiKey: (resource, params) =>
        httpClient(`${apiUrl}/users/me/apiKey`).then(({ json }) => ({
            data: json,
        })),

    resetUserApiKey: (resource, params) =>
        httpClient(`${apiUrl}/users/me/apiKey/reset`).then(({ json }) => ({
            data: json,
        })),
};

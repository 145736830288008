import * as React from 'react';
import {
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    NumberField,
    EditButton,
    SimpleForm,
    TextInput,
    EmailField,
    SimpleFormIterator,
    NumberInput,
    ArrayInput,
    AutocompleteInput
} from 'react-admin';

export const DomainList = props => (
    <List {...props}>
        <Datagrid>
            <NumberField source="id" label="id"/>
            <TextField source="domain" label="domain" />
            <EmailField source="contact" label="contact" />
            <NumberField source="ttl" label="ttl"/>
            <TextField source="serial" label="serial"/>
            <EditButton />
        </Datagrid>
    </List>
);

const DomainName = ({ record }) => {
    return <span>Domain {record ? `"${record.domain}"` : ''}</span>;
};

export const DomainEdit = () => (
    <Edit title={<DomainName />}>
        <SimpleForm>
            <NumberInput disabled source="id" label="id"/>
            <TextInput disabled source="domain" label="domain"/>
            <TextInput disabled source="contact" type="email" label="contact"/>
            <NumberInput disabled source="ttl" label="ttl" />
            <NumberInput disabled source="serial" label="serial"/>
            <ArrayInput source="records">
                <SimpleFormIterator inline getItemLabel={id => `#${id}`}>
                      <TextInput source="name" label="name" isRequired/>
                      <AutocompleteInput source="type" label="type" isRequired choices={[
                            {id: 'A', name: 'A'},
                            {id: 'AAAA', name: 'AAAA'},
                            {id: 'NS', name: 'NS'},
                            {id: 'SOA', name: 'SOA'},
                            {id: 'CNAME', name: 'CNAME'},
                            {id: 'MX', name: 'MX'},
                            {id: 'TXT', name: 'TXT'},
                            {id: 'SPF', name: 'SPF'},
                            {id: 'URI', name: 'URI'},
                            {id: 'CAA', name: 'CAA'},
                            {id: 'SRV', name: 'SRV'},
                            {id: 'HTTPS', name: 'HTTPS'},
                            {id: 'MD', name: 'MD'},
                            {id: 'MF', name: 'MF'},
                            {id: 'MB', name: 'MB'},
                            {id: 'MG', name: 'MG'},
                            {id: 'MR', name: 'MR'}, 
                            {id: 'NULL', name: 'NULL'},
                            {id: 'WKS', name: 'WKS'},
                            {id: 'PTR', name: 'PTR'},
                            {id: 'HINFO', name: 'HINFO'},
                            {id: 'MINFO', name: 'MINFO'},
                            {id: 'RP', name: 'RP'},
                            {id: 'X25', name: 'X25'},
                            {id: 'AFSDB', name: 'AFSDB'},
                            {id: 'ISDN', name: 'ISDN'},
                            {id: 'RT', name: 'RT'},
                            {id: 'NSAP', name: 'NSAP'},
                            {id: 'SIG', name: 'SIG'},
                            {id: 'KEY', name: 'KEY'},
                            {id: 'PX', name: 'PX'},
                            {id: 'LOC', name: 'LOC'},
                            {id: 'NXT', name: 'NXT'},
                            {id: 'NAPTR', name: 'NAPTR'},
                            {id: 'KX', name: 'KX'},
                            {id: 'CERT', name: 'CERT'},
                            {id: 'DNAME', name: 'DNAME'},
                            {id: 'OPT', name: 'OPT'},
                            {id: 'APL', name: 'APL'},
                            {id: 'DS', name: 'DS'},
                            {id: 'SSHFP', name: 'SSHFP'},
                            {id: 'IPSECKEY', name: 'IPSECKEY'},
                            {id: 'RRSIG', name: 'RRSIG'},
                            {id: 'NSEC', name: 'NSEC'},
                            {id: 'DNSKEY', name: 'DNSKEY'},
                            {id: 'DHCID', name: 'DHCID'},
                            {id: 'NSEC3', name: 'NSEC3'},
                            {id: 'NSEC3PARAM', name: 'NSEC3PARAM'},
                            {id: 'TLSA', name: 'TLSA'},
                            {id: 'SMIMEA', name: 'SMIMEA'},
                            {id: 'CDS', name: 'CDS'},
                            {id: 'CDNSKEY', name: 'CDNSKEY'},
                            {id: 'OPENPGPKEY', name: 'OPENPGPKEY'},
                            {id: 'CSYNC', name: 'CSYNC'},
                            {id: 'ZONEMD', name: 'ZONEMD'},
                            {id: 'SVCB', name: 'SVCB'},
                            {id: 'AVC', name: 'AVC'},
                            {id: 'DLV', name: 'DLV'},
                        ]} />
                      <NumberInput source="ttl" label="ttl" defaultValue={600}/>
                      <TextInput source="data" isRequired label="data"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export const DomainCreate = () => (
    <Create>
        <SimpleForm>
          <TextInput source="domain" label="domain" isRequired />
          <TextInput source="contact" type="email" label="contact" isRequired />
          <NumberInput source="ttl" label="ttl" isRequired />
        </SimpleForm>
    </Create>
);

import * as React from "react";
import { Admin, CustomRoutes, Resource, AppBar, Layout } from 'react-admin';
import { Route } from "react-router-dom";
import dataProvider from './utils/dataProvider';
import authProvider from './utils/authProvider'
import Dashboard from './layout/dashboard'
import {DomainList, DomainCreate, DomainEdit} from './domains'
import { ProfileEdit } from './profile';
import { MyUserMenu } from "./layout/userMenu";

const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu />} />;

const MyLayout = props => <Layout {...props} appBar={MyAppBar} />;

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider} dashboard={Dashboard}>
  <Resource name="domains" list={DomainList} create={DomainCreate} edit={DomainEdit} />
  <CustomRoutes>
            <Route path="/profile" element={<ProfileEdit />} />
  </CustomRoutes>
  </Admin>
);

export default App;

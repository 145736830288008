import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

export default () => (
    <Card>
        <CardHeader title="Welcome!" />
        <CardContent>This dashboard is currently under construction!</CardContent>
    </Card>
);

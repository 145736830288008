const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request('https://api.baka.net/v1/users/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            credentials: 'include',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('auth', JSON.stringify(auth));
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },
    checkAuth: () => localStorage.getItem('auth')
        ? Promise.resolve()
        : Promise.reject(),
    getPermissions: () => {
        // Required for the authentication to work
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getIdentity: () => {
        try {
            const { id } = JSON.parse(localStorage.getItem('auth'));
            return Promise.resolve({ id, fullName: id });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    logout: () => {
        const request = new Request('https://api.baka.net/v1/users/logout', {
            method: 'GET',
            credentials: 'include',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        fetch(request)
            .catch(() => {
                throw new Error('Network error')
            });
        localStorage.removeItem('auth');
        return Promise.resolve();
        },
    // ...
};

export default authProvider;

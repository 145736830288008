import React, { useCallback, useMemo } from 'react';
import { PasswordInput, TextInput, SimpleForm,
   useDataProvider, useNotify, useGetIdentity, SaveContextProvider,
   SaveButton, Toolbar } from 'react-admin';
import { useMutation } from 'react-query';

const MyToolbar = () => (
  <Toolbar>
      <SaveButton label="Save" />
  </Toolbar>
);

export const ProfileEdit = ({ staticContext, ...props }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { mutate, isLoading } = useMutation();
  const { identity, isLoading: identityLoading } = useGetIdentity();

  const handleSave = (values) => {
    mutate(
      ['users', 'updateUserProfile', {data: values}],
      (values) => dataProvider.updateUserProfile({data: values}),
      {
          onSuccess: () => {
            notify("Your profile has been updated", "info");
          },
          onFailure: () => {
            notify(
              "A technical error occured while updating your profile. Please try later.",
              "warning"
            );
          }
        }
    );
  };

  const saveContext = useMemo(() => ({
    save: handleSave,
    saving: isLoading,
    mutationMode: "pessimistic"
  }), [isLoading, handleSave]);

  if (identityLoading) {
    return null;
  }

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm onSubmit={handleSave} record={identity ? identity : {}} toolbar={<MyToolbar />}>
        <TextInput source="id" label="username" disabled/>
        <PasswordInput source="password" label="password" isRequired/>
      </SimpleForm>
    </SaveContextProvider>
  );
};